import React from "react";

import RangeCalendar from "rc-calendar/lib/RangeCalendar";
import "rc-calendar/dist/rc-calendar.css";
import moment from "moment";

import { getCalenderLocalication } from "../utils/Translations";

function MyCalendar(props) {
  const { onNewDateRange, dateFrom, dateTo } = props;

  const onDateSelect = dates => {
    if (dates[1]) {
      // Only call the callback if they have select from AND to
      dates[1] = moment(dates[1]).endOf("day");
      onNewDateRange(dates);
    }
  };
  return (
    <div>
      <RangeCalendar
        // Forces re-render, we want this as it actually "flips the page"
        // i.e. displays the currently selected range in case the user did not
        // manually select the period (e.g. through buttons that select prev/next month)
        key={dateFrom + dateTo}
        locale={getCalenderLocalication()}
        selectedValue={[moment(dateFrom), moment(dateTo)]}
        showToday={true}
        onChange={onDateSelect}
        showDateInput={false}
        // An ugly hack to prevent the calendar
        // appearing underneath other buttons
        style={{ zIndex: 2 }}
      />
    </div>
  );
}

export default MyCalendar;
