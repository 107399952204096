import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  FormControl,
  FormGroup,
  ControlLabel,
} from "react-bootstrap/lib";

import { translate } from "../../utils/Translations";

// prettier-ignore
const translations = {
  "Edit project": { is: "Breyta verkefni" },
  "Project name": { is: "Nafn á verkefni" },
  "Project description (optional)": { is: "Lýsing á verkefni (valkvætt)" },
  "Close window": { is: "Loka glugga" },
};

const t = key => translate(key, translations);

function EditProject({ show, project, onSubmit, onCloseModal }) {
  const [projectName, setProjectName] = useState(project ? project.name : "");
  const [description, setDescription] = useState(project?.description ?? "");
  const [descriptionAreaRowCount, setDescriptionAreaRowCount] = useState(3);

  const adjustDescriptionAreaRowCount = text => {
    if (!text) {
      setDescriptionAreaRowCount(3);
      return;
    }

    const lineBreaks = text.split("\n").length;
    const estimatedLines = Math.max(lineBreaks, Math.ceil(text.length / 50));
    // Min 3, Max 10 rows
    setDescriptionAreaRowCount(Math.min(10, Math.max(3, estimatedLines)));
  };

  useEffect(() => {
    // Adjust row count when description changes
    adjustDescriptionAreaRowCount(description);
  }, [description]);

  if (!project) return <div />;

  const projectNameHasNotChanged = projectName === project.name;
  const descriptionHasNotChanged = description === project.description;
  const submitDisabled =
    (projectNameHasNotChanged && descriptionHasNotChanged) ||
    projectName.length === 0;

  const submit = () => {
    const data = { id: project.id };
    if (!projectNameHasNotChanged) data["new_name"] = projectName;
    if (!descriptionHasNotChanged) data["new_description"] = description;
    onSubmit(data);
    onCloseModal();
  };

  return (
    <Modal show={show} onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Edit project")}</Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={e => {
          e.preventDefault();
          submit();
        }}
      >
        <Modal.Body>
          <FormGroup controlId="formProjectNameControl">
            <ControlLabel>{t("Project name")}</ControlLabel>
            <FormControl
              type="text"
              onChange={e => setProjectName(e.target.value)}
              value={projectName}
            />
          </FormGroup>
          <FormGroup controlId="formProjectDescriptionControl">
            <ControlLabel>{t("Project description (optional)")}</ControlLabel>
            <FormControl
              componentClass="textarea"
              rows={descriptionAreaRowCount}
              onChange={e => setDescription(e.target.value)}
              value={description}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={submitDisabled}
            type="submit"
            bsStyle="success"
            block={true}
            onClick={e => {
              e.preventDefault();
              submit();
            }}
          >
            {t("Edit project")}
          </Button>
          <Button block={true} onClick={onCloseModal}>
            {t("Close window")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default EditProject;
