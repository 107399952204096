import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  FormControl,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { translate } from "../../utils/Translations";

// prettier-ignore
const translations = {
  "Create project": { is: "Búa til verkefni" },
  "Project name": { is: "Nafn á verkefni" },
  "Project description (optional)": { is: "Lýsing á verkefni (valkvætt)" },
  "Add project": { is: "Bæta við verkefni" },
  "Close window": { is: "Loka glugga" },
};

const t = key => translate(key, translations);

function CreateProject({ show, onSubmit, onCloseModal }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionAreaRowCount, setDescriptionAreaRowCount] = useState(3);

  const adjustDescriptionAreaRowCount = text => {
    if (!text) {
      setDescriptionAreaRowCount(3);
      return;
    }

    const lineBreaks = text.split("\n").length;
    const estimatedLines = Math.max(lineBreaks, Math.ceil(text.length / 50));
    // Min 3, Max 10 rows
    setDescriptionAreaRowCount(Math.min(10, Math.max(3, estimatedLines)));
  };

  useEffect(() => {
    // Adjust row count when description changes
    adjustDescriptionAreaRowCount(description);
  }, [description]);

  const handleNameChange = e => setName(e.target.value);
  const handleDescriptionChange = e => setDescription(e.target.value);

  const submitDisabled = name.length === 0;

  const setDefaultState = () => {
    setName("");
    setDescription("");
  };

  const closeModal = () => {
    onCloseModal();
    setDefaultState();
  };

  const submit = data => {
    onSubmit(data);
    setDefaultState();
  };

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Create project")}</Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={e => {
          e.preventDefault();
          submit({ name, description });
        }}
      >
        <Modal.Body>
          <FormGroup controlId="formProjectNameControl">
            <ControlLabel>{t("Project name")}</ControlLabel>
            <FormControl
              type="text"
              value={name}
              onChange={handleNameChange}
              placeholder={t("Project name")}
            />
          </FormGroup>
          <FormGroup controlId="formProjectDescriptionControl">
            <ControlLabel>{t("Project description (optional)")}</ControlLabel>
            <FormControl
              componentClass="textarea"
              rows={descriptionAreaRowCount}
              value={description}
              onChange={handleDescriptionChange}
              placeholder={t("Project description (optional)")}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={submitDisabled}
            type="submit"
            bsStyle="success"
            block={true}
            onClick={e => {
              e.preventDefault();
              submit({ name, description });
            }}
          >
            {t("Add project")}
          </Button>
          <Button onClick={closeModal} block={true}>
            {t("Close window")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateProject;
