import API from "./../api";
import {
  setRollbarCompanyPerson,
  setRollbarEmployeePerson,
  setRollbarToAnonymousUser,
} from "../utils/Rollbar";
import { setSentryCompanyUser, setSentryEmployeeUser } from "../utils/Sentry";

// const handleError = (dispatch, error, typeToDispatch) => {
//   // TODO: Our api extracts the message out of all errors that the server throws
//   // After we fix that and only pass the error here we can get the http status from it
//   // That will allow us to hook the function to ALL errors in all actions
//   // This also allows us to send explicit message in some cases and deal with known errors.
//
//   if (error.statusText === 401) {
//     dispatch({
//       type: "LOG_OUT",
//       payload: error
//     });
//   }

//   if (error.statusText >= 500) {
//     dispatch({
//       type: "SHOW_ERROR", // This requires us to add SHOW_ERROR to error reducer
//       payload: "Tímavera er niðri"
//     });
//   }

//   return dispatch({
//     type: typeToDispatch,
//     payload: error
//   });
// };

export const authenticateAdmin = (user, pass) => {
  return dispatch => {
    dispatch({ type: "FETCH_AUTHENTICATION_ADMIN" });
    API.authenticateAdmin(user, pass).then(
      res =>
        dispatch({
          type: "FETCH_AUTHENTICATION_ADMIN_FULFILLED",
          payload: res,
        }),
      error =>
        dispatch({
          type: "FETCH_AUTHENTICATION_ADMIN_REJECTED",
          payload: error,
        })
    );
  };
};

export const adminLoginAs = id => {
  return dispatch => {
    dispatch({ type: "FETCH_AUTHENTICATION" });
    API.adminLoginAs(id).then(
      res =>
        dispatch({
          type: "FETCH_AUTHENTICATION_FULFILLED",
          payload: res,
        }),
      error =>
        dispatch({
          type: "FETCH_AUTHENTICATION_REJECTED",
          payload: error,
        })
    );
  };
};

export const fetchAdminScripts = () => {
  return dispatch => {
    dispatch({ type: "FETCH_ADMIN_SCRIPTS" });
    return API.getAdminScripts().then(
      res =>
        dispatch({
          type: "FETCH_ADMIN_SCRIPTS_FULFILLED",
          payload: res,
        }),
      error =>
        dispatch({
          type: "FETCH_ADMIN_SCRIPTS_REJECTED",
          payload: error,
        })
    );
  };
};

export const runAdminScript = (script, data) => {
  return dispatch => {
    dispatch({ type: "RUN_ADMIN_SCRIPT" });
    API.runAdminScript(script, data).then(
      res =>
        dispatch({
          type: "RUN_ADMIN_SCRIPT_FULFILLED",
          payload: res,
        }),
      error =>
        dispatch({
          type: "RUN_ADMIN_SCRIPT_REJECTED",
          payload: error,
        })
    );
  };
};

export const authenticate = (user, pass, isTimeClock = false) => {
  let apiAuthenticate = isTimeClock
    ? API.authenticateTimeClock
    : API.authenticate;

  return dispatch => {
    dispatch({ type: "FETCH_AUTHENTICATION" });
    apiAuthenticate(user, pass).then(
      response => {
        dispatch({ type: "CLEAR_ERROR" });
        setRollbarCompanyPerson(response.id, response.name);
        setSentryCompanyUser(response.id, response.name);

        return dispatch({
          type: "FETCH_AUTHENTICATION_FULFILLED",
          payload: response,
        });
      },
      error => {
        dispatch({
          type: "FETCH_AUTHENTICATION_REJECTED",
          payload: error,
        });
      }
    );
  };
};

export const authenticateToken = token => {
  return dispatch => {
    dispatch({ type: "FETCH_AUTHENTICATION" });
    API.authenticateToken(token).then(
      response => {
        dispatch({ type: "CLEAR_ERROR" });
        setRollbarCompanyPerson(response.id, response.name);
        setSentryCompanyUser(response.id, response.name);

        return dispatch({
          type: "FETCH_AUTHENTICATION_FULFILLED",
          payload: response,
        });
      },
      error => {
        dispatch({
          type: "FETCH_AUTHENTICATION_REJECTED",
          payload: error,
        });
      }
    );
  };
};

export const authenticateChangePasswordToken = data => {
  return dispatch => {
    dispatch({ type: "FETCH_AUTHENTICATION" });
    return API.authenticateChangePasswordToken(data).then(
      response => {
        dispatch({ type: "CLEAR_ERROR" });
        setRollbarCompanyPerson(response.id, response.name);
        setSentryCompanyUser(response.id, response.name);

        return dispatch({
          type: "FETCH_AUTHENTICATION_FULFILLED",
          payload: response,
        });
      },
      error => {
        dispatch({
          type: "FETCH_AUTHENTICATION_REJECTED",
          payload: error,
        });
      }
    );
  };
};

export const checkIfLoggedIn = () => {
  return dispatch => {
    dispatch({ type: "FETCH_AUTHENTICATION" });
    API.checkIfLoggedIn().then(
      res => {
        dispatch({ type: "CLEAR_ERROR" });

        // If we got a response and res is not the empty string "" it means the
        // user (company or employee) were logged in.
        if (res) {
          setRollbarCompanyPerson(res.id, res.name);
          setSentryCompanyUser(res.id, res.name);

          dispatch({
            type: "FETCH_AUTHENTICATION_FULFILLED",
            payload: res,
          });
        } else {
          // The login check request succeeded but the user is either anonymous
          // or a previously authorized user session has expired. Note that it
          // may seem strange to dispatch a log out action in the case of an
          // anonymous user browsing our front page. The LOG_OUT actions is
          // just an action that sets the Redux store in a neutral state for
          // outsiders.
          dispatch({ type: "LOG_OUT", payload: {} });
        }
      },
      error => {
        dispatch({
          type: "LOG_OUT",
          payload: error,
        });
      }
    );
  };
};

export const logOut = () => {
  return dispatch => {
    dispatch({ type: "LOG_OUT", payload: {} });
    API.logOut().then(() => {
      setRollbarToAnonymousUser();
    });
  };
};

export const switchToTimeClock = () => {
  return dispatch => {
    dispatch({ type: "FETCH_AUTHENTICATION" });
    API.switchToTimeClock().then(
      company => {
        dispatch({
          type: "FETCH_AUTHENTICATION_FULFILLED",
          payload: company,
        });
      },
      error =>
        dispatch({
          type: "FETCH_AUTHENTICATION_REJECTED",
          payload: error,
        })
    );
  };
};

export const fetchCompanies = () => {
  return dispatch => {
    dispatch({ type: "FETCH_COMPANIES" });
    API.getCompanies().then(
      companies =>
        dispatch({
          type: "FETCH_COMPANIES_FULFILLED",
          payload: companies,
        }),
      error =>
        dispatch({
          type: "FETCH_COMPANIES_REJECTED",
          payload: error,
        })
    );
  };
};

export const createCompany = data => {
  return dispatch => {
    dispatch({ type: "CREATE_COMPANY" });
    API.createCompany(data).then(
      company =>
        dispatch({
          type: "CREATE_COMPANY_FULLFILLED",
          payload: company,
        }),
      error =>
        dispatch({
          type: "CREATE_COMPANY_REJECTED",
          payload: error,
        })
    );
  };
};

export const createPaydayBilling = data => {
  return dispatch => {
    dispatch({ type: "CREATE_PAYDAY_BILLING" });
    return API.createPaydayBilling(data).then(
      success =>
        dispatch({
          type: "CREATE_PAYDAY_BILLING_FULFILLED",
          payload: success,
        }),
      error =>
        dispatch({
          type: "CREATE_PAYDAY_BILLING_REJECTED",
          payload: error,
        })
    );
  };
};

export const cancelBilling = reason => {
  return dispatch => {
    dispatch({ type: "CANCEL_BILLING" });
    return API.cancelBilling({ reason }).then(
      success =>
        dispatch({
          type: "CANCEL_BILLING_FULFILLED",
          payload: success,
        }),
      error =>
        dispatch({
          type: "CANCEL_BILLING_REJECTED",
          payload: error,
        })
    );
  };
};

export const validatePaddleBilling = () => {
  return dispatch => {
    dispatch({ type: "VALIDATE_PADDLE_BILLING" });
    return API.checkIfLoggedIn().then(
      res => {
        if (res) {
          return dispatch({
            type: "VALIDATE_PADDLE_BILLING_FULFILLED",
            payload: res,
          });
        } else {
          return dispatch({
            type: "VALIDATE_PADDLE_BILLING_REJECTED",
            payload: "Unable to validate subscription",
          });
        }
      },
      error => {
        return dispatch({
          type: "VALIDATE_PADDLE_BILLING_REJECTED",
          payload: error,
        });
      }
    );
  };
};

export const fetchEmployees = () => {
  return dispatch => {
    dispatch({ type: "FETCH_EMPLOYEES" });
    API.getEmployees().then(
      employees =>
        dispatch({
          type: "FETCH_EMPLOYEES_FULFILLED",
          payload: employees,
        }),
      error =>
        dispatch({
          type: "FETCH_EMPLOYEES_REJECTED",
          payload: error,
        })
    );
  };
};

export const fetchTransactions = params => {
  return dispatch => {
    dispatch({ type: "FETCH_TRANSACTIONS" });
    return API.getTransactions(params).then(
      transactions =>
        dispatch({
          type: "FETCH_TRANSACTIONS_FULFILLED",
          payload: transactions,
        }),
      error =>
        dispatch({
          type: "FETCH_TRANSACTIONS_REJECTED",
          payload: error,
        })
    );
  };
};

export const fetchProjects = () => {
  return dispatch => {
    dispatch({ type: "FETCH_PROJECTS" });
    return API.getProjects().then(
      projects =>
        dispatch({
          type: "FETCH_PROJECTS_FULFILLED",
          payload: projects,
        }),
      error =>
        dispatch({
          type: "FETCH_PROJECTS_REJECTED",
          payload: error,
        })
    );
  };
};

export const refreshTransactions = (workLogId, query) => {
  return dispatch => {
    dispatch({ type: "FETCH_REFRESH_TRANSACTIONS" });
    API.getRefreshTransactions(workLogId, query).then(
      transactions =>
        dispatch({
          type: "FETCH_REFRESH_TRANSACTIONS_FULFILLED",
          payload: transactions,
        }),
      error =>
        dispatch({
          type: "FETCH_REFRESH_TRANSACTIONS_REJECTED",
          payload: error,
        })
    );
  };
};

export const fetchLocations = id => {
  return dispatch => {
    dispatch({ type: "FETCH_LOCATIONS" });
    API.getLocations(id).then(
      locations =>
        dispatch({
          type: "FETCH_LOCATIONS_FULFILLED",
          payload: locations,
        }),
      error =>
        dispatch({
          type: "FETCH_LOCATIONS_REJECTED",
          payload: error,
        })
    );
  };
};

export const createEmployee = data => {
  return dispatch => {
    dispatch({ type: "CREATE_EMPLOYEE" });
    API.createEmployee(data).then(
      employee =>
        dispatch({
          type: "CREATE_EMPLOYEE_FULLFILLED",
          payload: employee,
        }),
      error =>
        dispatch({
          type: "CREATE_EMPLOYEE_REJECTED",
          payload: error,
        })
    );
  };
};

export const editEmployee = data => {
  return dispatch => {
    dispatch({ type: "EDIT_EMPLOYEE" });
    API.editEmployee(data).then(
      employee =>
        dispatch({
          type: "EDIT_EMPLOYEE_FULFILLED",
          payload: employee,
        }),
      error =>
        dispatch({
          type: "EDIT_EMPLOYEE_REJECTED",
          payload: error,
        })
    );
  };
};

export const enableEmployee = id => {
  return dispatch => {
    dispatch({ type: "ENABLE_EMPLOYEE" });
    API.enableEmployee(id).then(
      employee =>
        dispatch({
          type: "ENABLE_EMPLOYEE_FULFILLED",
          payload: employee,
        }),
      error =>
        dispatch({
          type: "ENABLE_EMPLOYEE_REJECTED",
          payload: error,
        })
    );
  };
};

export const disableEmployee = id => {
  return dispatch => {
    dispatch({ type: "DISABLE_EMPLOYEE" });
    API.disableEmployee(id).then(
      employee =>
        dispatch({
          type: "DISABLE_EMPLOYEE_FULFILLED",
          payload: employee,
        }),
      error =>
        dispatch({
          type: "DISABLE_EMPLOYEE_REJECTED",
          payload: error,
        })
    );
  };
};

export const createProject = data => {
  return dispatch => {
    dispatch({ type: "CREATE_PROJECT" });
    API.createProject(data).then(
      employee =>
        dispatch({
          type: "CREATE_PROJECT_FULLFILLED",
          payload: employee,
        }),
      error =>
        dispatch({
          type: "CREATE_PROJECT_REJECTED",
          payload: error,
        })
    );
  };
};

export const closeProject = id => {
  return dispatch => {
    dispatch({ type: "CLOSE_PROJECT" });
    API.closeProject(id).then(
      project =>
        dispatch({
          type: "CLOSE_PROJECT_FULFILLED",
          payload: project,
        }),
      error =>
        dispatch({
          type: "CLOSE_PROJECT_REJECTED",
          payload: error,
        })
    );
  };
};

export const openProject = id => {
  return dispatch => {
    dispatch({ type: "OPEN_PROJECT" });
    API.openProject(id).then(
      project =>
        dispatch({
          type: "OPEN_PROJECT_FULFILLED",
          payload: project,
        }),
      error =>
        dispatch({
          type: "OPEN_PROJECT_REJECTED",
          payload: error,
        })
    );
  };
};

export const editProject = data => {
  return dispatch => {
    dispatch({ type: "EDIT_PROJECT" });
    API.editProject(data).then(
      project =>
        dispatch({
          type: "EDIT_PROJECT_FULFILLED",
          payload: project,
        }),
      error =>
        dispatch({
          type: "EDIT_PROJECT_REJECTED",
          payload: error,
        })
    );
  };
};

export const addWorkLogComment = data => {
  return dispatch => {
    dispatch({ type: "ADD_WORK_LOG_COMMENT" });
    API.addWorkLogComment(data).then(
      workLog =>
        dispatch({
          type: "ADD_WORK_LOG_COMMENT_FULFILLED",
          payload: workLog,
        }),
      error =>
        dispatch({
          type: "ADD_WORK_LOG_COMMENT_REJECTED",
          payload: error,
        })
    );
  };
};

export const addWorkLog = data => {
  return dispatch => {
    dispatch({ type: "ADD_WORK_LOG" });
    return API.addWorkLog(data).then(
      workLog =>
        dispatch({
          type: "ADD_WORK_LOG_FULFILLED",
          payload: workLog,
        }),
      error =>
        dispatch({
          type: "ADD_WORK_LOG_REJECTED",
          payload: error,
        })
    );
  };
};

export const changeWorkLog = data => {
  return dispatch => {
    dispatch({ type: "CHANGE_WORK_LOG" });
    API.changeWorkLog(data).then(
      workLog =>
        dispatch({
          type: "CHANGE_WORK_LOG_FULFILLED",
          payload: workLog,
        }),
      error =>
        dispatch({
          type: "CHANGE_WORK_LOG_REJECTED",
          payload: error,
        })
    );
  };
};

export const deleteWorkLog = id => {
  return dispatch => {
    dispatch({ type: "DELETE_WORK_LOG" });
    API.deleteWorkLog(id).then(
      workLog =>
        dispatch({
          type: "DELETE_WORK_LOG_FULFILLED",
          payload: workLog,
        }),
      error =>
        dispatch({
          type: "DELETE_WORK_LOG_REJECTED",
          payload: error,
        })
    );
  };
};

export const createReport = data => {
  return dispatch => {
    dispatch({ type: "CREATE_REPORT" });
    return API.createReport(data).then(
      report =>
        dispatch({
          type: "CREATE_REPORT_FULFILLED",
          payload: report,
        }),
      error =>
        dispatch({
          type: "CREATE_REPORT_LOG_REJECTED",
          payload: error,
        })
    );
  };
};

export const updateSettings = settings => {
  return dispatch => {
    dispatch({ type: "UPDATE_SETTINGS" });
    return API.settings(settings).then(
      company =>
        dispatch({
          type: "UPDATE_SETTINGS_FULFILLED",
          payload: company,
        }),
      error =>
        dispatch({
          type: "UPDATE_SETTINGS_REJECTED",
          payload: error,
        })
    );
  };
};

export const authenticateEmployee = (company, employee, pass) => {
  return dispatch => {
    dispatch({ type: "FETCH_AUTHENTICATION" });
    API.authenticateEmployee(company, employee, pass).then(
      response => {
        dispatch({ type: "CLEAR_ERROR" });
        setRollbarEmployeePerson(
          response.id,
          response.name,
          response.company.id,
          response.company.name
        );

        setSentryEmployeeUser(
          response.id,
          response.name,
          response.company.id,
          response.company.name
        );

        return dispatch({
          type: "FETCH_AUTHENTICATION_FULFILLED",
          payload: response,
        });
      },
      error => {
        dispatch({
          type: "FETCH_AUTHENTICATION_REJECTED",
          payload: error,
        });
      }
    );
  };
};

export const fetchTransactionsForEmployee = params => {
  return dispatch => {
    dispatch({ type: "FETCH_TRANSACTIONS" });
    return API.getTransactionsForEmployee(params).then(
      transactions =>
        dispatch({
          type: "FETCH_TRANSACTIONS_FULFILLED",
          payload: transactions,
        }),
      error =>
        dispatch({
          type: "FETCH_TRANSACTIONS_REJECTED",
          payload: error,
        })
    );
  };
};

export const addWorkLogCommentForEmployee = data => {
  return dispatch => {
    dispatch({ type: "ADD_WORK_LOG_COMMENT" });
    API.addWorkLogCommentForEmployee(data).then(
      workLog =>
        dispatch({
          type: "ADD_WORK_LOG_COMMENT_FULFILLED",
          payload: workLog,
        }),
      error =>
        dispatch({
          type: "ADD_WORK_LOG_COMMENT_REJECTED",
          payload: error,
        })
    );
  };
};

export const fetchLocationsForEmployee = id => {
  return dispatch => {
    dispatch({ type: "FETCH_LOCATIONS" });
    API.getLocationsForEmployee(id).then(
      locations =>
        dispatch({
          type: "FETCH_LOCATIONS_FULFILLED",
          payload: locations,
        }),
      error =>
        dispatch({
          type: "FETCH_LOCATIONS_REJECTED",
          payload: error,
        })
    );
  };
};

export const addWorkLogForEmployee = data => {
  return dispatch => {
    dispatch({ type: "ADD_WORK_LOG" });
    return API.addWorkLogForEmployee(data).then(
      locations =>
        dispatch({
          type: "ADD_WORK_LOG_FULFILLED",
          payload: locations,
        }),
      error =>
        dispatch({
          type: "ADD_WORK_LOG_REJECTED",
          payload: error,
        })
    );
  };
};

export const changeWorkLogForEmployee = data => {
  return dispatch => {
    dispatch({ type: "CHANGE_WORK_LOG" });
    API.changeWorkLogForEmployee(data).then(
      workLog =>
        dispatch({
          type: "CHANGE_WORK_LOG_FULFILLED",
          payload: workLog,
        }),
      error =>
        dispatch({
          type: "CHANGE_WORK_LOG_REJECTED",
          payload: error,
        })
    );
  };
};

export const updateLastSeenNotification = data => {
  return dispatch => {
    dispatch({ type: "UPDATE_LAST_SEEN_NOTIFICATION" });
    return API.updateLastSeenNotification(data).then(
      company =>
        dispatch({
          type: "UPDATE_LAST_SEEN_NOTIFICATION_FULFILLED",
          payload: company,
        }),
      error =>
        dispatch({
          type: "UPDATE_LAST_SEEN_NOTIFICATION_REJECTED",
          payload: error,
        })
    );
  };
};

export const changePassword = data => {
  return dispatch => {
    dispatch({ type: "CHANGE_PASSWORD" });
    API.changePassword(data).then(
      report =>
        dispatch({
          type: "CHANGE_PASSWORD_FULFILLED",
          payload: report,
        }),
      error =>
        dispatch({
          type: "CHANGE_PASSWORD_REJECTED",
          payload: error,
        })
    );
  };
};

// Non API actions
export const clearReport = () => {
  return dispatch => dispatch({ type: "CLEAR_REPORT" });
};

export const dismissError = () => {
  return dispatch => dispatch({ type: "CLEAR_ERROR" });
};

export const dismissUserError = () => {
  return dispatch => dispatch({ type: "CLEAR_USER_ERROR" });
};

export const sortCompanies = attribute => {
  return dispatch => dispatch({ type: "SORT_COMPANY", payload: attribute });
};

export const sortProjects = attribute => {
  return dispatch => dispatch({ type: "SORT_PROJECT", payload: attribute });
};

export const sortEmployees = attribute => {
  return dispatch => dispatch({ type: "SORT_EMPLOYEE", payload: attribute });
};

export const sortTransactions = attribute => {
  return dispatch => dispatch({ type: "SORT_TRANSACTION", payload: attribute });
};
