// Import polyfills we require. This must be the first line in this file.
import "./utils/Polyfills";

// Import Sentry error reporting. Must be initialized as early as possible.
import "./utils/Sentry";

import React from "react";
import { hydrate, render } from "react-dom";
import { Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./store";
// Import so our site can be translated to multiple languages
import AppWrapper from "./pages/AppWrapper";

// The order of the below imports is important since each subsequent import
// overwrites the previous for CSS selectors they have in common. First we load
// our Bootstrap theme Vitality, then we overwrite few selectors in the theme
// with our own modifications and then any global CSS we want.
import "./styles/vitality-red.css";
// import "./styles/vitality-red-modified.css";
import "./styles/vitality-red-modifications.css";
import "./index.css";

const rootElement = document.getElementById("root");

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Route component={AppWrapper} />
      </BrowserRouter>
    </Provider>
  );
};

// Pre-render app with react-snap if appropriate, otherwise just render normally
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
