import * as Sentry from "@sentry/react";

import { HOST } from "../api";

const hostname = window.location.hostname;
const isTimaveraIs = hostname === "timavera.is";
const isTimaveraCom = hostname === "timavera.com";
const isTimaveraIe = hostname === "timavera.ie";
const isTimaveraCoUk = hostname === "timavera.co.uk";
const isStagingTimaveraIs = hostname === "staging.timavera.is";
const isStagingTimaveraCom = hostname === "staging.timavera.com";

const isProduction =
  isTimaveraIs || isTimaveraCom || isTimaveraIe || isTimaveraCoUk;
const isStaging = isStagingTimaveraIs || isStagingTimaveraCom;
const environment = isProduction ? "production" : "staging";

/** Initializes Sentry error reporting */
Sentry.init({
  dsn: "https://edba3969f9792324351efe6a6b6a473b@o1245797.ingest.us.sentry.io/4508639080218624",
  tunnel: HOST + "/api/se",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  environment: environment, // "production" or "staging"
  enabled: isProduction || isStaging, // Don't send errors from localhost

  initialScope: {
    tags: {
      site: hostname, // "timavera.is", "staging.timavera.com", etc
    },
  },

  // =============================== Tracing ===================================
  // Ratio of transactions to capture. 1.0 = 100%, 0.1 = 10%
  tracesSampleRate: 1.0,

  // Set 'tracePropagationTargets' to control for which URLs distributed
  // tracing should be enabled. If understood correctly, each API request to
  // the URLs in the list will be augmented with the `sentry-trace` and
  // `baggage` headers. If we had Sentry on our backend this would enable us
  // to get a trace across the website and API. Useful for example if the user
  // had an error on the website that was caused by abnormal API load.
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // =========================== Session Replay ================================
  // This sets the sample rate at 10%. You may want to change it to 100% while
  // in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 1.0,

  // If you're not already sampling the entire session, change the sample rate
  // to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
});

/**
 * Sets the Sentry user object so we know which company encountered errors.
 *
 * @param companyID {number} Example: 1337
 * @param companyName {string} Example: "timavera"
 */
export function setSentryCompanyUser(companyID, companyName) {
  Sentry.setUser({ id: `Company: "${companyName}" (${companyID})` });
}

/**
 * Sets the Sentry user object so we know which employee encountered errors.
 *
 * @param employeeID {number} Example: 42
 * @param employeeName {string} Example: "olafur"
 * @param companyID {number} Example: 1337
 * @param companyName {string} Example: "timavera"
 */
export function setSentryEmployeeUser(
  employeeID,
  employeeName,
  companyID = null,
  companyName = null
) {
  const employee = `Employee: "${employeeName}" (${employeeID})`;
  const company = `Company: "${companyName}" (${companyID})`;

  Sentry.setUser({ id: employee + " " + company });
}
