import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Button from "react-bootstrap-button-loader";
import { ControlLabel, Form, FormControl, FormGroup } from "react-bootstrap";

import API from "../api";
import { setRollbarEmployeePerson } from "../utils/Rollbar";
import { translateWithGlobals } from "../utils/Translations";
import { typeOfErrors } from "../utils/ErrorHandling";
import {
  feedbackStyle,
  HelpBlockIfError,
  HelpBlockIfNetworkError,
} from "./CompanyLoginForm";

import s from "./EmployeeLoginForm.module.css";
import { setSentryEmployeeUser } from "../utils/Sentry";

// prettier-ignore
const translations = {
  "Log in as an employee": { is: "Innskrá sem starfsmaður" },

  "Wrong company name": { is: "Rangt fyrirtækjanafn" },
  "Wrong employee name": { is: "Rangt starfsmanna nafn" },
  "Wrong employee password": { is: "Rangt starfsmanna lykilorð" },
};

const t = key => translateWithGlobals(key, translations);

function EmployeeLoginForm(props) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [company, setCompany] = useState("");
  const [employee, setEmployee] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false); // If error: Axios error object

  const { wrongCompanyName, wrongEmployeeName, wrongEmployeePass } =
    typeOfErrors(error);

  const employeeLoginError =
    wrongCompanyName || wrongEmployeeName || wrongEmployeePass;

  const handleLogin = e => {
    e.preventDefault();
    setLoading(true);
    setError(false);

    API.authenticateEmployee(company, employee, password).then(
      response => {
        setLoading(false);

        setRollbarEmployeePerson(
          response.id,
          response.name,
          response.company.id,
          response.company.name
        );

        setSentryEmployeeUser(
          response.id,
          response.name,
          response.company.id,
          response.company.name
        );

        return dispatch({
          type: "FETCH_AUTHENTICATION_FULFILLED",
          payload: response,
        });
      },
      error => {
        setLoading(false);
        setError(error);
      }
    );
  };

  if (!loading && user?.role === "EMPLOYEE") {
    return <Redirect to="/employee/app" />;
  }

  return (
    <Form onSubmit={handleLogin}>
      <FormGroup
        bsSize="large"
        controlId="employeeLoginForm-Company"
        validationState={wrongCompanyName ? "error" : null}
      >
        <ControlLabel>{t("Company")}</ControlLabel>
        <FormControl
          disabled={loading}
          type="text"
          onChange={e => setCompany(e.target.value)}
          placeholder={t("Company")}
        />
        <FormControl.Feedback style={feedbackStyle} />
        <HelpBlockIfError
          isError={wrongCompanyName}
          message={t("Wrong company name")}
        />
      </FormGroup>
      <FormGroup
        bsSize="large"
        controlId="employeeLoginForm-Employee"
        validationState={wrongEmployeeName ? "error" : null}
      >
        <ControlLabel>{t("Employee")}</ControlLabel>
        <FormControl
          disabled={loading}
          type="text"
          onChange={e => setEmployee(e.target.value)}
          placeholder={t("Employee")}
        />
        <FormControl.Feedback style={feedbackStyle} />
        <HelpBlockIfError
          isError={wrongEmployeeName}
          message={t("Wrong employee name")}
        />
      </FormGroup>
      <FormGroup
        bsSize="large"
        controlId="employeeLoginForm-Password"
        validationState={wrongEmployeePass ? "error" : null}
      >
        <ControlLabel>{t("Password")}</ControlLabel>
        <FormControl
          disabled={loading}
          type="password"
          onChange={e => setPassword(e.target.value)}
          placeholder={t("Password")}
        />
        <FormControl.Feedback style={feedbackStyle} />
        <HelpBlockIfError
          isError={wrongEmployeePass}
          message={t("Wrong employee password")}
        />
      </FormGroup>

      <HelpBlockIfNetworkError
        error={error}
        handled={employeeLoginError}
        codeLocation="Employee login, /src/pages/employee/login.js"
      />

      <Button
        bsSize="large"
        bsStyle="primary"
        block={true}
        disabled={loading}
        loading={loading}
        type="submit"
        className={s.logInButton}
      >
        {t("Log in as an employee")}
      </Button>
    </Form>
  );
}

export default EmployeeLoginForm;
