import React from "react";
import { Grid, Row, Col, Glyphicon } from "react-bootstrap";

import { translateWithGlobals } from "../../utils/Translations";
import TimaveraEmail from "../../components/TimaveraEmail";

// prettier-ignore
const translations = {
  "Contact us": { "is": "Hafa samband" },
  "Email": { "is": "Tölvupóstur" },
  "Phone": { "is": "Sími" },
  "Personal mobile numbers if needed": {
    "is": "Persónuleg símanúmer ef þörf er á"
  },

  "sentence1": {
    en: "Need more information or support with the setup of the Tímavera " +
      "time tracking app?",
    is: "Þarftu frekar upplýsingar eða aðstoð við uppsetningu á Tímaveru " +
      "tímaskráningarkerfinu og stimpilklukku appinu?",
  },
  "sentence2": {
    en: "Please get in touch!",
    is: "Endilega hafðu samband!",
  },
};

const t = key => translateWithGlobals(key, translations);

/**
 * Randomizes the order of Finnbogi and Jón Hafdal when displaying personal
 * phone numbers to avoid one of them being more likely to receive the majority
 * of the phone calls.
 */
// eslint-disable-next-line no-unused-vars
const FinnbogiAndJonPhoneNumbers = () => {
  let person1 = null;
  let person2 = null;

  const jon = (
    <>
      Jón Hafdal: <a href="tel:+3547701532">+354 770 1532</a>
    </>
  );

  const finnbogi = (
    <>
      Finnbogi: <a href="tel:+3546953384">+354 695 3384</a>
    </>
  );

  if (Math.random() < 0.5) {
    person1 = jon;
    person2 = finnbogi;
  } else {
    person1 = finnbogi;
    person2 = jon;
  }

  return (
    <>
      {person1}
      <br />
      {person2}
    </>
  );
};

const Email = () => (
  <>
    <h3 style={{ marginTop: "1em" }}>
      <Glyphicon glyph="envelope" /> {t("Email")}
    </h3>
    <p style={{ fontSize: "1.5em" }}>
      <strong>
        <TimaveraEmail />
      </strong>
    </p>
  </>
);

const PhoneNumbers = () => (
  <>
    <h3 style={{ marginTop: "1.5em" }}>
      <Glyphicon glyph="phone" /> {t("Phone")}
    </h3>
    <p style={{ fontSize: "1.5em" }}>
      <strong>
        <a href="tel:+3545395118">+354 539 5118</a>
      </strong>
    </p>

    <div style={{ marginTop: "2em" }}>
      <p>
        {t("Personal mobile numbers if needed")}:
        <br />
        Magnús: <a href="tel:+3548499783">+354 849 9783</a>
        <br />
        Ólafur: <a href="tel:+3547727895">+354 772 7895</a>
      </p>
    </div>
  </>
);

/** Information on how we can be contacted. */
export default function ContactUs(props) {
  return (
    <section id="contact" className="bg-dark">
      <Grid className="container">
        <Row>
          <Col className="text-center">
            <h2>{t("Contact us")}</h2>
            <hr className="colored" />
          </Col>
          <Col
            xs={10}
            sm={10}
            md={8}
            lg={8}
            xsOffset={1}
            smOffset={1}
            mdOffset={2}
          >
            <p className="text-center">{t("sentence1")}</p>
            <p className="text-center">{t("sentence2")}</p>

            <div className="text-center">
              <Email />
              <PhoneNumbers />
            </div>
          </Col>
        </Row>
      </Grid>
    </section>
  );
}
